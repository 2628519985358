<template>
  <section class="aa-faq">
    <v-container>
      <v-layout column>
        <v-skeleton-loader
          width="100%"
          :loading="this.filteredLoading"
          type="heading, text"
          class="aa-faq__heading"
        >
          <h1>{{ filteredField.title }}</h1>
          <div
            class="aa-faq__heading-subtitle"
            v-html="filteredField.description"
          ></div>
        </v-skeleton-loader>

        <v-skeleton-loader
          width="100%"
          :loading="this.filteredLoading"
          type="accordion@6"
        >
          <v-expansion-panels multiple class="aa-faq__accordion">
            <v-expansion-panel v-for="(item, i) in accordionField" :key="i">
              <v-expansion-panel-header>
                {{ item.question }}
              </v-expansion-panel-header>
              <v-expansion-panel-content v-html="item.answer">
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-skeleton-loader>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      panel: [],
    };
  },
  computed: {
    ...mapGetters("faq", ["error", "loading", "field"]),
    filteredField: function () {
      if (!this.field || this.field === "") {
        return this.field;
      }

      return this.field;
    },
    filteredLoading: function () {
      if (!this.loading || this.loading === "") {
        return this.loading;
      }

      return this.loading;
    },
    ...mapGetters("faqAccordion", ["error", "loading", "accordion"]),
    accordionField: function () {
      if (!this.accordion || this.accordion === "") {
        return this.accordion;
      }

      return this.accordion;
    },
  },
};
</script>

<style lang="scss">
@import "./faq-style.scss";
</style>
