<template>
  <main class="aa-faq">
    <FAQHeading />
  </main>
</template>

<script>
import FAQHeading from "@/components/Heading/FAQHeading.vue";

export default {
  data: () => {
    return {
      field: null,
    };
  },
  components: { FAQHeading },
  created: async function() {
    this.field = this.$store.dispatch("faq/getField");
    this.$store.dispatch("faqAccordion/getField");

    if (this.field) {
      this.$store.dispatch("loader/showLoader", {
        loading: false,
      });
    }
  },
};
</script>
